import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { Helmet } from "react-helmet-async";
import { UserFooter } from "../Layout";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { SuccessToast, ErrorToast } from "../../../Utils/SweetAlert";
import { Loader } from "../../../Utils/Loader";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { AddSubscription } from "../../../Services/Api/payment";

const PricingCard = styled(Card)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  height: "100%",
  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
  transition: "all 0.3s ease-in-out",
  "&:hover": {
    transform: "translateY(-5px)",
    boxShadow: "0 6px 12px rgba(0, 0, 0, 0.15)",
  },
}));

const PricingButton = styled(Button)(({ theme }) => ({
  marginTop: "auto",
  fontWeight: "bold",
  transition: "all 0.3s ease-in-out",
  "&:hover": {
    transform: "scale(1.05)",
  },
}));

const FeatureItem = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
}));

const Pricing = (props) => {
  const navigate = useNavigate();
  const { loading, userDetails } = props;
  const [loadingComponent, setLoadingComponent] = useState(false);
  const wordStackSubscription =
    userDetails?.user?.wordStackSubscription || false;

  const addSubscription = async ({ planid = 10 }) => {
    setLoadingComponent(true);
    await AddSubscription({ plan: planid })
      .then((res) => {
        setLoadingComponent(false);
        if (res?.data.status) {
          SuccessToast(
            res?.data?.message || "Subscription purchased successfully."
          );
          navigate("/user/stack");
        } else {
          ErrorToast(res?.data?.message || "Server Error!!");
          if (res?.data?.data?.insufficiant_credit) {
            navigate("/user/credit");
          }
        }
      })
      .catch((err) => {
        ErrorToast(err?.response?.data?.message || "Server Error!!");
        setLoadingComponent(false);
      });
  };

  const handleClick = (plan) => {
    // console.log("plan = ", plan);
    if (!wordStackSubscription) {
      addSubscription({ planid: plan });
    }
  };

  const pricingPlans = [
    {
      title: "Word Pulse",
      titleDesc: "Month",
      monthlyPrice: 10,
      features: [
        "Unlimited Words",
        "30 Days validity",
        "Cancel at least 1 day ago",
        "PPC stand for PhasePulse Credits",
      ],
    },
  ];

  useEffect(() => {}, []);

  return !loadingComponent ? (
    <>
      <Helmet>
        <title>Subscription Plans | PhrasePulse</title>
      </Helmet>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">Plans</h3>
                <Breadcrumb>
                  <Breadcrumb.Item>
                    <span onClick={() => navigate("/user/dashboard")}>
                      Dashboard
                    </span>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>Plans</Breadcrumb.Item>
                </Breadcrumb>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">Pricing Plans</h4>
                </div>
                <div className="card-body">
                  <Grid container spacing={4}>
                    {pricingPlans.map((plan, index) => (
                      <Grid item xs={12} sm={6} md={4} key={index}>
                        <PricingCard>
                          <CardContent>
                            <Typography
                              variant="h4"
                              component="h2"
                              gutterBottom
                            >
                              {plan.title}
                            </Typography>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "baseline",
                                mb: 2,
                              }}
                            >
                              {`PPC`}
                              <Typography variant="h3" component="span">
                                {plan.monthlyPrice}
                              </Typography>
                              <Typography variant="subtitle1" sx={{ ml: 1 }}>
                                / {plan.titleDesc}
                              </Typography>
                            </Box>
                            {plan.features.map((feature, idx) => (
                              <FeatureItem key={idx}>
                                <ArrowForwardIcon />
                                <Typography variant="body1">
                                  {feature}
                                </Typography>
                              </FeatureItem>
                            ))}
                          </CardContent>
                          <CardActions>
                            <PricingButton
                              variant="contained"
                              color={
                                wordStackSubscription ? "error" : "primary"
                              }
                              fullWidth
                              onClick={() => handleClick(plan.monthlyPrice)}
                            >
                              {wordStackSubscription
                                ? `Cancel Plan`
                                : `Subscribe Now`}
                            </PricingButton>
                          </CardActions>
                        </PricingCard>
                      </Grid>
                    ))}
                  </Grid>
                </div>
              </div>
            </div>
          </div>
        </div>
        <UserFooter />
      </div>
    </>
  ) : (
    <Loader loading={loading} />
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state?.reducers?.loading,
    userDetails: state?.reducers?.userDetails,
  };
};

const mapActionsToProps = (actions) => {
  return {};
};

export default connect(mapStateToProps, mapActionsToProps)(Pricing);
